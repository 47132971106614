/* Default font styles */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-image: url("/public/18410.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Page container */
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.header h1 {
  font-size: 3rem;
  margin: 0;
}

.header nav a {
  color: #333;
  font-size: 1.2rem;
  margin-left: 1rem;
  text-decoration: none;
}

/* Homepage hero */
.homepage-hero {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #000;
  position: relative;
}

.homepage-hero h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.homepage-hero p {
  font-size: 1.2rem;
  width: 50%;
}

.homepage-hero button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.homepage-hero button:hover {
  background-color: #ddd;
  color: #000;
}

/* Homepage features */
.homepage-features {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;
}

.homepage-features .feature {
  flex-basis: calc(33.33% - 2rem);
  margin: 0 1rem;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  text-align: center;
}

.homepage-features .feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.homepage-features .feature p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

/* Experiences page */
.experiences {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 2rem;
  margin-top: 4rem;
}

.experience {
  background-color: #f7f7f7;
  padding: 2rem;
  border-radius: 0.5rem;
}

.experience h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.experience p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  width: 500px;
}

/* About page */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.about-heading {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #2a2a2a;
  text-transform: uppercase;
}

.about-paragraph {
  font-size: 1.2rem;
  text-align: center;
  color: #5c5c5c;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
}

.about-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-top: 3rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.about-link {
  font-size: 1.2rem;
  color: #2a2a2a;
  text-decoration: none;
  border-bottom: 2px solid #2a2a2a;
  transition: all 0.3s ease-in-out;
  margin-top: 2rem;
}

.about-link:hover {
  color: #fff;
  background-color: #2a2a2a;
}

/* Layout Page */
.icon-column {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 110px;
  left: 50px;
}

.icon-column a {
  display: block;
  margin-bottom: 1rem;
}

.icon-column a svg {
  font-size: 2em;
}

/* Contact Page */
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.contact-container h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
}

form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.form-group label {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

input,
textarea {
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
}

button {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #5a5a5a;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.success-message {
  font-family: "Montserrat", sans-serif;
}
